<template>
  <div v-if="list.length">
    <div class="companyInfo" v-if="list.length">
      <i class="el-icon-date" @click="dialogVisible = true"></i>
      <div v-if="lastArr.length" class="showLISt">
        <p v-for="(item, index) in lastArr" :key="index" class="showItem">
          <span v-if="item.status == 1">{{$t('begining')}}：</span>
          <span v-if="item.status == 2">{{$t('underway')}}：</span>
          <span v-if="item.status == 3">{{$t('meetingReturnLive')}}：</span>
          <span class="name" @click="gotoWatch(item)">{{
            item.issue_en | priorFormat(item.issue_zh, LOCALE)
          }}</span>
        </p>
      </div>
    </div>
    <div v-else class="empty"></div>
    <div>
      <el-dialog :title="$t('boothAgenda')" :visible.sync="dialogVisible" width="500px">
        <div class="block">
          <el-timeline v-for="(item, index) in list" :key="index">
            <el-timeline-item color="#027FFF" :timestamp="item.date" placement="top">
              <div class="item" v-for="(ii, dd) in item.dataList" :key="dd">
                <p class="time">
                  {{ ii.start_time | secondFormat("T") }} -
                  {{ ii.end_time | secondFormat("T") }}
                </p>
                <div class="name">
                  <p>{{ ii.issue_en | priorFormat(ii.issue_zh, LOCALE) }}</p>
                  <p class="guest" v-if="ii.guests_id">
                    {{$t('meetingUserAll')}}：{{ ii.guests_id }}
                  </p>
                </div>
                <div>
                  <el-button v-if="!ii.isEnd" type="text" @click="gotoWatch(ii)"
                    >{{$t('meetingEnter')}}</el-button
                  >
                  <el-button
                    v-else
                    type="text"
                    style="color: #e6a23c"
                    @click="gotoWatch(ii)"
                    >{{$t('meetingReturnLive')}}</el-button
                  >
                </div>
              </div>
            </el-timeline-item>
          </el-timeline>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import boothMixin from "./mixin";
import { formatDate } from "~/baseUtils";
export default {
   mixins: [boothMixin],
  data() {
    return {
      dialogVisible: false,
      list: [],
      lastArr: [],
    };
  },
  mounted() {
    this.getMsg();
    console.log();
  },
  methods: {
    gotoWatch(item) {
      console.log(item)
       this.$log({
            type: 1,
            id: "Hall.business.booth.agenda",
            topic: "Hall.business",
            content: JSON.stringify({
              agenda_id: item.id,
            }),
          });
      window.open(item.watch_url);
    },
    async getMsg() {
      let e = await this.$store.dispatch("baseStore/getBoothAgaen", {
        meeting_booth_id: this.$route.query.booth_id,
      });
      let msg = e.data;
      let noBegin = [];
      2;
      let begin = [];
      let end = [];
      msg.forEach((ee) => {
        ee.isEnd = false;
        if (this.$moment().unix() > ee.end_time) {
          ee.isEnd = true;
          ee.status = 3;
          end.push(ee);
        }
        if (this.$moment().unix() < ee.start_time) {
          ee.status = 1;
          noBegin.push(ee);
        }
        if (
          ee.start_time <= this.$moment().unix() &&
          this.$moment().unix() <= ee.end_time
        ) {
          ee.status = 2;
          begin.push(ee);
        }
      });
      this.lastArr = [].concat(begin, noBegin, end).slice(0, 2);
      this.list = formatDate(msg);
        this.initComplete();
    },
  },
};
</script>

<style scoped lang="less">
.empty {
  width: 100%;
  height: 30px;
}
.companyInfo {
  width: 100%;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .showLISt {
    display: flex;
    align-items: center;
    width: 1220px;
    justify-content: space-between;
    .showItem {
      width: 550px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      .name {
        color: #409eff;
        cursor: pointer;
      }
    }
  }
}
.el-icon-date {
  font-size: 24px;
}
.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px 0;
  .time {
    width: 100px;
  }
  .name {
    width: 250px;
    p {
      width: 250px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      
    }
    .guest{
      font-size: 12px;
      color: #999;
      margin-top: 5px;
    }
  }
}
</style>
