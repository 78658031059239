<template>
  <div class="checkFarChannel">
    <div class="back" :style="{ height: height + 'px' }">
      <div class="chatStatus" v-if="!channelStatus.online">
        <el-image
          src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/icons/noVideo.png"
        ></el-image>
        <div>{{ $t("OffLine") }}</div>
      </div>
      <div class="chatStatus tc" v-if="channelStatus.online">
        <el-image
          class="imgWidth"
          src="https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/videoChatIcon/noVideoAvatar.png"
        ></el-image>
      </div>
    </div>
    <div id="videoChat" class="videoChannel" :style="{ height: height + 'px' }">
      <div class="videoStatus" v-if="channelStatus.online">
        <img
          v-if="channelStatus.audio"
          class="statusWidth"
          src="https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/videoChatIcon/otherMicOpen.png"
        />
        <img
          v-else
          class="statusWidth"
          src="https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/videoChatIcon/otherMicClose.png"
        />
        <img
          v-if="channelStatus.video"
          class="statusWidth"
          src="https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/videoChatIcon/otherVideoOpen.png"
        />
        <img
          v-else
          class="statusWidth"
          src="https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/videoChatIcon/otherVideoClose.png"
        />
      </div>
    </div>
  </div>
</template>
<script>
import basicChat from "~/basePlugins/basicVideoChat";

export default {
  props: {
    info: {
      type: Object,
      default: () => {
        return {};
      },
    },
    height: {
      type:  String,
      default: null,
    },
  },
  data() {
    return {
      Chat: null,
      channelStatus: {
        audio: false,
        online: false,
      },
    };
  },
  watch: {
    info(e) {
      console.log('e--witch',e);
      this.initVideoChat();
    },
  },

  methods: {
    initVideoChat() {
      console.log(this.info);
      this.Chat = new basicChat(
        {
          channel_id: this.info.live_id + "",
          user_id: "999",
          type: "1v1",
          preview_id: this.info.user_id,
          auto_publish: false,
          doms: {
            otherWindowDom: "videoChat",
          },
        },
        this.$store,
        null,
        {
          userStatusChange: (e) => {
            let obj = Object.assign({}, e.otherStatus);
            this.channelStatus = obj;
          },
        }
      );
    },
    clockVideoChat() {
      console.log(this.Chat);
      this.Chat.quitSDK();
    },
  },
};
</script>
<style lang="less" scoped>
.videoChannel {
  width: 100%;
  height: 490px;
  border: 1px solid #bbb;
  z-index: 1;
}
.checkFarChannel {
  position: relative;
}
.back {
  width: 100%;
  height: 490px;
  position: absolute;
  top: 0;
  z-index: 0;
  background: linear-gradient(135deg, #383838, #424242);
  div {
    color: #777;
  }
}
.videoStatus {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 5px 5px 2px 5px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 0 0 0 6px;
}
.chatStatus {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.statusWidth {
  width: 22px;
  height: 22px;
  margin: 0 2px;
}
.imgWidth {
  width: 100px;
}
</style>