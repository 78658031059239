<template>
  <div v-if="imageList.length > 0">
    <div class="album">
      <div class="titleBox">
        <p>{{ $t("companyImage") }}</p>
      </div>
      <div class="imgData">
        <template v-for="(it, index) in imageList">
          <div class="iamgeItem cursor" v-if="it.img_url" :key="index">
            <div @click="imageChange(it)">
              <al-image
                class="image"
                style="width: 100%; height: 206px"
                :src="it.img_url"
                fit="cover"
              ></al-image>
              <div class="num">
                {{ it.imgSum }} <span v-if="LOCALE == 'zh'">图</span>
              </div>
            </div>
            <div class="div1"></div>
            <div class="div2"></div>
          </div>
        </template>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible" width="1200px">
      <div class="dialogImage">
        <div class="imageTitle clearfix">
          <div class="fl">
            {{ selectImage.name_en | priorFormat(selectImage.name_zh, LOCALE) }}
          </div>
          <div class="fr">
            <el-button
              type="primary"
              size="small"
              plain
              @click="toggleImage(1)"
              :disabled="lastDisabled"
              >{{ $t("last") }}</el-button
            >
            <el-button
              type="primary"
              plain
              :disabled="nextDisabled"
              size="small"
              @click="toggleImage(2)"
              >{{ $t("nextNumber") }}</el-button
            >
          </div>
        </div>
        <div class="clearfix dialogData">
          <div
            class="dialogItem fl"
            v-for="(item, index) in selectImage.pic_list"
            :key="index"
          >
            <al-image
              class="image"
              style="width: 100%; height: 186px"
              :src="item.url"
              fit="cover"
              :preview-src-list="[item.url]"
            ></al-image>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import boothMixin from "./mixin";
export default {
  mixins: [boothMixin],
  data() {
    return {
      imageList: [],
      dialogVisible: false,
      selectImage: {},
      lastDisabled: false,
      nextDisabled: false,
    };
  },
  mounted() {
    this.booth_id = this.$route.query.booth_id;
    this.getList();
  },
  methods: {
    // 获取展台图片集
    async getList() {
      let params = {
        booth_id: this.booth_id,
      };
      let data = await this.$store.dispatch(
        "baseConsole/booth_pictureCollection",
        params
      );
      if (data.success) {
        data.data = data.data.filter((item) => {
          return item.pic_list.length != 0;
        });
        data.data.forEach((it, index) => {
          it.sortId = it.collection_id;
          it.img_url = it.pic_list.find((item) => item.url).url;
          it.imgSum = it.pic_list.length;
        });
        this.imageList = data.data;

      }
      this.initComplete();
    },
    toggleImage(value) {
      
      let valueIndex = "";
      this.imageList.forEach((it, index) => {
        if (it == this.selectImage) {
          valueIndex = index;
        }
      });
      if (value == 1) {
        this.$log({
          type:1,
          id:'Hall.business.booth.image.last',
          topic:'Hall.business',
        
        })
        if (valueIndex != this.imageList.length - 2) {
          this.nextDisabled = false;
        }
        if (valueIndex == 1) {
          this.lastDisabled = true;
        } else {
          this.lastDisabled = false;
        }
        this.selectImage = this.imageList[valueIndex - 1];
      } else {
         this.$log({
          type:1,
          id:'Hall.business.booth.image.next',
          topic:'Hall.business',
        
        })
        if (valueIndex != 1) {
          this.lastDisabled = false;
        }
        if (valueIndex == this.imageList.length - 2) {
          this.nextDisabled = true;
        } else {
          this.nextDisabled = false;
        }
        this.selectImage = this.imageList[valueIndex + 1];
      }
    },
    imageChange(item) {
        this.$log({
          type:1,
          id:'Hall.business.image',
          topic:'Hall.business',
        
        })
      this.dialogVisible = true;
      this.selectImage = item;
      let valueIndex = "";
      this.imageList.forEach((it, index) => {
        if (it == item) {
          valueIndex = index;
        }
      });
      if (valueIndex == 0) {
        this.lastDisabled = true;
      } else {
        this.lastDisabled = false;
      }
      if (valueIndex == this.imageList.length - 1) {
        this.nextDisabled = true;
      } else {
        this.nextDisabled = false;
      }
    },
  },
};
</script>

<style scoped lang="less">
.album {
  width: 341px;
  min-height: 300px;
  background: #fff;
  overflow-y: auto;
  margin-bottom: 12px;
  .titleBox {
    padding: 0 20px;
    font-size: 16px;
    font-weight: 600;
    line-height: 60px;
    border-bottom: 1px solid #dcdfe6;
  }
  .imgData {
    height: 500px;
    overflow-y: auto;
    padding: 10px 20px;
  }
  .iamgeItem {
    // overflow: hidden;
    width: 296px;
    margin-bottom: 30px;
    position: relative;
    .image {
      z-index: 10;
      border: 1px solid #ececec;
    }
    .div1 {
      width: 100%;
      max-width: 365px;
      height: 206px;
      // margin: 3px;
      top: 4px;
      left: 4px;
      z-index: 8;
      border: 1px solid #c0c4cc;
      position: absolute;
      background: #fff;
    }
    .div2 {
      width: 100%;
      max-width: 365px;
      height: 206px;
      // margin: 3px;
      top: 8px;
      left: 8px;
      z-index: 5;
      border: 1px solid #c0c4cc;
      position: absolute;
    }
    div {
      color: #7d8695;
    }
    .tc {
      margin-top: 10px;
    }
    .num {
      // width: 66px;
      padding: 1px 16px;
      background: #1f292e;
      border-radius: 11px;
      opacity: 0.8;
      position: absolute;
      right: 12px;
      top: 170px;
      z-index: 12;
      color: #fff;
    }
    .delete {
      padding: 1px 18px;
      background: #1f292e;
      border-radius: 11px;
      opacity: 0.8;
      position: absolute;
      right: 12px;
      top: 12px;
      z-index: 12;
    }
  }
}
.dialogData {
  height: 410px;
  overflow-y: auto;
}
.dialogItem {
  width: 275px;
  margin-left: 16px;
  margin-bottom: 10px;
  &:nth-child(4n + 1) {
    margin-left: 0;
  }

  .image {
    height: 186px;
  }
}
.imageTitle {
  margin-bottom: 16px;
}
</style>