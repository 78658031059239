<template>
  <div>
    <div v-if="loading" class="loadingBox">
      <loading></loading>
    </div>
    <div class="content_area" v-show="!loading">
      <div class="previewBtn tr" v-if="$route.query.isPreview">
        <el-button
          size="small"
          type="primary"
          @click="$router.push('console/conference/boothManage')"
          >{{ $t("closePreview") }}</el-button
        >
      </div>
      <!-- <el-breadcrumb separator-class="el-icon-arrow-right" style="padding:15px 0">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>活动管理</el-breadcrumb-item>
    </el-breadcrumb> -->
      <!-- <companyWithVideo /> -->
      <companyInfoModel @initSuccess="initSuccess(1)" />
      <agendaTime @initSuccess="initSuccess(2)" />
      <div class="otherCompanyMsg">
        <div class="otherCompanyMsgFl">
          <aboutAs @initSuccess="initSuccess(3)" />
          <business @initSuccess="initSuccess(4)" />
          <comment @initSuccess="initSuccess(5)" />
        </div>
        <div>
          <album @initSuccess="initSuccess(6)" />
          <dataDown @initSuccess="initSuccess(7)" />
          <eCode @initSuccess="initSuccess(8)" />
        </div>
      </div>
      <redEnvelope v-if="PJSource == 10" @initSuccess="initSuccess(9)" />
    </div>
    <cardDialog ref="cardDialog"></cardDialog>
  </div>
</template>
<script>
// import companyWithVideo from "./components/companyWithVideo";
import companyInfoModel from "./components/companyInfoModel";
import agendaTime from "./components/agendaTime";
import aboutAs from "./components/aboutAs";
import album from "./components/album";
import business from "./components/bussiness";
import comment from "./components/comment";
import dataDown from "./components/dataDown";
import eCode from "./components/eCode";
import redEnvelope from "./components/redEnvelope";
import loading from "~/baseComponents/loading/loading1";
import cardDialog from "@/baseComponents/card/cardIsDialog";

export default {
  components: {
    companyInfoModel,
    agendaTime,
    aboutAs,
    album,
    business,
    comment,
    dataDown,
    eCode,
    loading,
    redEnvelope,
    cardDialog,
  },
  data() {
    return {
      loading: true,
      componentsCount: 8,
      initCount: 0,
    };
  },
  methods: {
    initSuccess(e) {
      this.initCount++;
      if (this.initCount == this.componentsCount) {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="less">
.previewBtn {
  margin-bottom: 15px;
}
.loadingBox {
  height: 100vh;
}
.otherCompanyMsg {
  display: flex;
  justify-content: space-between;
  //   align-items: center;
}
// .otherCompanyMsgFl{

// }
.empty {
  width: 100%;
  height: 12px;
}
</style>
