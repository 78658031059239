<template>
  <div class="userChat clearfix">
    <div class="left fl" v-if="!isChatHide">
      <checkFarChannel
        :info="miniInfo"
        ref="checkFar"
        :isChat="true"
        :height="'370'"
      ></checkFarChannel>
    </div>
    <div class="right fl">
      <div class="enterChat tc">{{ $t("enterMeetingRoom") }}</div>
      <div class="idCode" v-loading="idcodeLoading">
        <!-- v-if="LOCALE == 'zh'" -->
        <div id="idCode" v-if="LOCALE == 'en'"></div>
        <el-image :src="img_url" class="img" v-else></el-image>
      </div>
      <div class="tc">
        <span v-if="LOCALE == 'en'"
          >You can use a mobile device to scan this QR code to enter the
          negotiation
          <span
            title="Android users recommend Google Chrome, IPhone users should scan with a native camera and open it in Safari."
            ><i class="el-icon-question cursor"></i
          ></span>
        </span>
        <span v-else>微信扫描二维码进入聊天室</span>
      </div>
    </div>
    <div class="line fl"></div>
    <div class="rightBtn fr">
      <el-button
        type="primary"
        size="small"
        class="enterButton"
        @click="enterChat"
        >{{ $t("enterDirectly") }}</el-button
      >
    </div>
  </div>
</template>
<script>
import checkFarChannel from "~/baseComponents/checkFarChannel";
import { handleToVideoChatPage, getVideoChatPage } from "~/baseUtils";

export default {
  props: {
    miniInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    chatType: {
      type: String,
      default: "seats",
    },
    isChatHide: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    miniInfo() {
      if (this.LOCALE == "en") {
        this.getQrCode();
      } else {
        this.checkParmas();
      }
    },
  },
  components: {
    checkFarChannel,
  },
  data() {
    return {
      img_url: "",
      idcodeLoading: false,
    };
  },
  methods: {
    async checkParmas() {
      try {
        this.idcodeLoading = true;
        let _params = {
          channel_id: this.miniInfo.live_id,
          user_id: this.USER_INFO.id,
          chat_type: this.chatType,
        };
        let params = this._encode(_params);
        let result = await this.$store.dispatch("baseStore/getShortAdress", {
          original: params,
        });
        if (result.success) {
          this.getMiniCode(result.data);
        } else {
          this.idcodeLoading = false;
        }
      } catch (e) {
        this.idcodeLoading = false;
      }
    },
    async getMiniCode(params) {
      try {
        let result = await this.$store.dispatch("baseStore/getMiniEcode", {
          page: "pages/expChat/expChat",
          width: 400,
          scene: params,
          source: this.PJSource,
        });
        if (result.success) {
          this.img_url = result.file;
        }
        this.idcodeLoading = false;
      } catch (e) {
        console.log(e);
      }
    },
    getQrCode() {
      this.$nextTick(() => {
        let url = getVideoChatPage(
          this.miniInfo.live_id,
          this.USER_INFO.id,
          this.chatType
        );
        if (!this.qrcode) {
          this.qrcode = new QRCode("idCode", {
            text: url,
            width: 180,
            height: 180,
            colorDark: "#000000",
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.H,
          });
        } else {
          this.qrcode.clear();
          this.qrcode.makeCode(url);
        }
      });
    },
    enterChat() {
      handleToVideoChatPage(
        this.miniInfo.live_id,
        this.USER_INFO.id,
        this.chatType,
        true
      );
    },
    closeVideo() {
      this.$refs.checkFar.clockVideoChat();
    },
  },
};
</script>
<style lang="less" scoped>
.userChat {
  .left {
    width: 500px;
  }
  .line {
    height: 200px;
    width: 1px;
    background: #ececec;
    margin: 80px 30px;
  }
  .right {
    width: 300px;
    padding: 0px 45px 20px;
    div {
      color: #1f292e;
    }
    .idCode {
      width: 200px;
      height: 200px;
      border: 1px solid #ececec;
      margin: 50px 0 12px;
      padding: 10px;
      .img {
        width: 180px;
        height: 180px;
      }
    }

    .enterChat {
      font-size: 24px;
      min-width: 250px;
    }
  }
  .rightBtn {
    padding-top: 150px;
    padding-right: 40px;
    /deep/.el-button--small,
    .el-button--small.is-round {
      padding: 9px 35px;
    }
  }
}
</style>