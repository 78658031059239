<template>
  <div v-if="drownList.length > 0">
    <div class="down">
      <div class="titleBox">
        <p>{{ $t("downloadZone") }}</p>
      </div>
      <div class="drownList">
        <div
          v-for="(it, index) in drownList"
          :key="index"
          class="drownItem textOverflow"
        >
          <span class="cursor" @click="drownChange(it)">{{ it.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import boothMixin from "./mixin";
export default {
  mixins: [boothMixin],
  data() {
    return {
      drownList: [],
    };
  },
  mounted() {
    this.booth_id = this.$route.query.booth_id;
    this.getList();
  },
  methods: {
    async getList() {
      let data = await this.$store.dispatch("baseConsole/getBoothData", {
        nopage: 1,
        booth_id: this.booth_id,
      });
      if (data.success) {
        this.drownList = data.data;
      }
      this.initComplete();
    },
    drownChange(it) {
     
       this.$log({
          type:1,
          id:'Hall.business.booth.download',
          topic:'Hall.business',
           content:JSON.stringify({
              fileId:it.id
          })
        })
      window.location.href = it.url;
      // window.open(it.url);
    },
  },
};
</script>

<style scoped lang="less">
.down {
  width: 341px;
  background: #fff;
  margin-bottom: 12px;
  .titleBox {
    padding: 0 20px;
    font-size: 16px;
    font-weight: 600;
    line-height: 60px;
    border-bottom: 1px solid #dcdfe6;
  }
  .drownList {
    padding: 0 20px;
    overflow-y: auto;
    height: 300px;
  }
  .drownItem {
    margin: 10px 0;
    span:hover {
      color: #0083f6;
    }
  }
}
</style>