<template>
  <div style="margin-top:20px">
    <div class="comment">
      <div class="titleBox">
        <p>{{ $t("comment") }}</p>
      </div>
      <div class="contentBox" v-loading="com_loading">
        <div class="inputBox">
          <!-- <img :src="avatar ? avatar : DEFAULT_AVATAR" alt="" /> -->
          <el-input
            class="inputSty"
            :placeholder="$t('entercontent')"
            size="small"
            v-model="input"
            @keyup.enter.native="handleSend"
            clearable
          >
          </el-input>
          <div>
            <el-button type="primary" size="small" @click="handleSend">{{
              $t("published")
            }}</el-button>
          </div>
        </div>
        <div class="totalBox">
          {{ $t("total") }}{{ total }}{{ $t("comments") }}
        </div>
        <div class="listBox">
          <commentItem
            v-for="(item, i) in comment_list"
            :key="i"
            :item="item"
            @del="del_child"
            @getCard="getCard"
          ></commentItem>
          <noDataImg v-if="comment_list.length == 0 && !com_loading" />
          <div class="paginationBlock" v-if="total != 0">
            <el-pagination
              @current-change="handleChange"
              :total="total"
              :hide-on-single-page="true"
              :page-size="5"
              layout="prev, pager, next"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import commentItem from "@/baseComponents/baseBooth/components/commentItem";
import boothMixin from "./mixin";
import cardDialog from "@/baseComponents/card/cardIsDialog";
export default {
  mixins: [boothMixin],
  // props:{
  //     company_id:{
  //         type:Number | String,
  //         default:125143
  //     }
  // },
  components: {
    commentItem,
    cardDialog,
  },
  data() {
    return {
      commented_company_id: "",
      company_id: "",
      avatar: "",
      com_loading: false,
      input: "",
      total: 0,
      start: 1,
      comment_list: [],
      currentId: null,
      row: {},
    };
  },
  mounted() {
    this.commented_company_id = this.$route.query.company_id;
    if(!this.$route.query.company_id){
      this.commented_company_id = this._decode(this.$route.query.parameter).company_id;
    }
    this.getList();
  },
  methods: {
    async getList() {
      this.com_loading = true;
      let params = {
        commented_company_id: this.commented_company_id,
        start: this.start - 1,
        limit: 5,
      };
      let result = await this.$store.dispatch(
        "baseStore/getCompanyCommentList",
        params
      );
      if (result && result.success) {
        this.comment_list = result.data;
        this.total = result.count;
      }
      this.com_loading = false;
      this.initComplete();
    },
    handleChange(e) {
      this.start = e;
      this.getList();
    },
    async handleSend() {
      if (!this.USER_INFO.id) {
        this.$router.push({ path: "/sign" });
        return;
      }
      if (!this.USER_INFO.company_id) {
        this.$message.warning(this.$t("yetJoinTip1"));
        this.$router.push({ path: "/console/company/basic" });
        return;
      }

      if (this.input == "") {
        this.$message.warning(this.$t("entercontent"));
        return;
      }
      this.com_loading = true;
      let params = {
        commented_company_id: this.commented_company_id,
        source: 5,
        content: this.input,
        user_id: this.USER_INFO.id,
        company_id: this.USER_INFO.company_id,
        audit_status: 0,
        meeting_id: this.MEETING_ID,
      };
      console.log('params',params)
      let save = await this.$store.dispatch(
        "baseStore/saveCompanyComment",
        params
      );
      if (save && save.success) {
        this.input = "";
        this.getList();
      }
    },
    del_child(e) {
      this.getList();
    },
    getCard(userId, companyId) {
      let row  = {
        user_id: userId,
        company_id: companyId,
      };
      // let row = this.row;
      this.$GLOBALEVENT.$emit("OPENISUSERCARD", { show: true, row });
      // this.currentId=userId
      // this.$nextTick(() => {

      //   this.$refs.pl_openDialog.openCardDialog({
      //     followed_user_id:userId,
      //     emit_company_id:companyId,
      //     show: true,
      //   })

      //   // this.$GLOBALEVENT.$emit("OPENUSERCard", {
      //   //   followed_user_id:userId,
      //   //   emit_company_id:companyId,
      //   //   show: true,
      //   // });
      // })
    },
  },
};
</script>

<style scoped lang="less">
.comment {
  width:943px;
  background: #fff;
  margin-bottom: 12px;
  padding: 0 20px;
  .titleBox {
    font-size: 16px;
    font-weight: 600;
    line-height: 60px;
    border-bottom: 1px solid #dcdfe6;
  }
  .contentBox {
    .inputBox {
      padding-top: 20px;
      padding-bottom: 20px;
      display: flex;
      align-items: center;
      img {
        width: 36px;
        height: 36px;
      }
      .inputSty {
        margin: 0 12px 0 0;
      }
    }
    .totalBox {
      height: 17px;
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      line-height: 17px;
    }
    .listBox {
      position: relative;
      min-height: 300px;
      .paginationBlock {
        text-align: right;
        margin-top: 15px;
        padding-bottom: 20px;
      }
    }
  }
}
</style>