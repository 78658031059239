import { render, staticRenderFns } from "./redEnvelope.vue?vue&type=template&id=c0a3f36e&scoped=true"
import script from "./redEnvelope.vue?vue&type=script&lang=js"
export * from "./redEnvelope.vue?vue&type=script&lang=js"
import style0 from "./redEnvelope.vue?vue&type=style&index=0&id=c0a3f36e&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c0a3f36e",
  null
  
)

export default component.exports