import relyConfig from '~/baseConfig/rely'

export default class {
    RTCAPPID = relyConfig.agoraData;
    rtc = {
        client: null,
        localAudioTrack: null,
        localVideoTrack: null,
    };
    store = null;
    options = {
        channel_id: '',
        user_id: '',
        type: '',
        autoPublish: true,
        preview_id: null,
        mediaConfig: {
            video: true,
            audio: true,
        }
    };
    doms = {
        selfWindowDom: '',
        otherWindowDom: ''
    };
    apis = {
        videoTokenApi: ''
    };
    userStatus1v1 = {
        selfStatus: { online: false },
        otherStatus: { online: false }
    };
    userStatusMulti = [];
    rtcRemoteUser = null;
    callBack = {};
    constructor(config, store, message, callBack) {
        //参数说明：config：配置项 :channel_id,必填，频道id;user_id,必填，用户id;type,必填，会议类型(1v1,group);apis,接口列表(根据需要传递)
        //store：vuex对象，因为请求要通过store来发
        //message：提示对象方法，例如element的message对象
        //callBack:状态回调
        let params = Object.assign({}, config);
        this.options.channel_id = params.channel_id;
        this.options.user_id = params.user_id ? parseInt(params.user_id) : null;
        this.options.type = params.type;
        this.options.auto_publish = params.auto_publish;
        this.options.mediaConfig = params.mediaConfig;
        this.options.preview_id = params.preview_id ? params.preview_id : null;
        this.doms = params.doms || {};
        this.store = store;
        this.callBack = callBack || {};
        this.apis.videoTokenApi = params.apis && params.apis.videoTokenApi || 'baseStore/getbiuniqueToken';
        this.init()
        this.handleRTCSDKEvent()
    }
    async init() {
        this.rtc.client = AgoraRTC.createClient({ mode: "rtc", codec: "h264" });            //初始化本地客户端
        this.joinChannel()
    }
    async createLocaleTrack(type) {                         //创建本地音视频流并推送本地音视频流
        let _type = type || 'all';
        if (_type == 'none') {
            this.handleUserStatusChange(
                Object.assign({
                    user_id: this.options.user_id,
                    online: true,
                    video: false,
                    audio: false,
                    type: 'self'
                })
            );
            return;
        }
        if (_type == 'audio' || _type == 'all') {
            this.rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack({ encoderConfig: "high_quality", AEC: true, ANS: true, AGC: true });        //高音质
            //this.rtc.localAudioTrack.play()
        }
        if (_type == 'video' || _type == 'all') {
            this.rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack({ optimizationMode: '' });                          //流畅优先

            if (this.options.type == '1v1') {
                this.rtc.localVideoTrack.play(this.doms.selfWindowDom)
            }
            if (this.options.type == 'multi') {
                this.rtc.localVideoTrack.play(`window${this.options.user_id}`)
            }
            setTimeout(() => {
                this.callBack.selfVideoInit && this.callBack.selfVideoInit()
            }, 200)
        }
        let mediaType = {};
        if (this.options.type == '1v1') {
            mediaType = {
                video: this.userStatus1v1.selfStatus.video,
                audio: this.userStatus1v1.selfStatus.audio
            }
        }
        if (this.options.type == 'multi') {
            let current = this.userStatusMulti.find(item => {
                return item.user_id = this.options.user_id
            }) || { audio: true, video: true }
            mediaType.video = current.video;
            mediaType.audio = current.audio;
        }
        
        if (_type == 'video') {
            await this.rtc.client.publish([this.rtc.localVideoTrack]);
            
            mediaType.video = true;
        } else if (_type == 'audio') {
            await this.rtc.client.publish([this.rtc.localAudioTrack]);
            mediaType.audio = true;
        } else if (_type == 'all') {
            await this.rtc.client.publish([this.rtc.localAudioTrack, this.rtc.localVideoTrack]);
            mediaType = { video: true, audio: true }
        }
        this.handleUserStatusChange(
            Object.assign({
                user_id: this.options.user_id,
                online: true,
                type: 'self'
            }, mediaType)
        )
    }

    async joinChannel() {                               //加入频道
        try {
            let tokenResult = await this.store.dispatch(
                this.apis.videoTokenApi,
                {
                    channel_id: this.options.channel_id,
                    user_id: this.options.user_id
                }
            )
            if (tokenResult.success) {
                const uid = await this.rtc.client.join(this.RTCAPPID, this.options.channel_id, tokenResult.data, this.options.user_id);
                this.callBack.joinChannelSuccess && this.callBack.joinChannelSuccess()
                this.handleUserStatusChange({
                    online: true,
                    user_id: this.options.user_id,
                    type: 'self'
                })
                if (this.options.type == '1v1' || this.options.type == 'multi') {
                    if (this.options.auto_publish) {
                        if (this.options.mediaConfig.video && this.options.mediaConfig.audio) {
                            this.createLocaleTrack()
                        } else if (this.options.mediaConfig.video && !this.options.mediaConfig.audio) {
                            this.createLocaleTrack('video')
                        } else if (!this.options.mediaConfig.video && this.options.mediaConfig.audio) {
                            this.createLocaleTrack('audio')
                        } else if (!this.options.mediaConfig.video && !this.options.mediaConfig.audio) {
                            this.createLocaleTrack('none')
                        }

                    }
                }
                console.log("加入频道成功");
            }
        } catch (e) {
            console.log(e)
        }
    }
    handleRTCSDKEvent() {
        this.rtc.client.on('user-joined', async (user) => {             //用户加入

            if (String(user.uid).slice(-3) == '999') {                //预览模式加入
                return;
            }
            this.callBack.userJoined && this.callBack.userJoined(user)
            this.handleUserStatusChange({
                user_id: user.uid,
                online: true,
                type: 'other'
            })
        })
        this.rtc.client.on("user-published", async (user, mediaType) => {       //远端发布音视频流
            this.rtcRemoteUser = user;
            this.callBack.userPublished && this.callBack.userPublished(user, mediaType);
            if (this.options.preview_id) {
                if (user.uid != this.options.preview_id) {
                    return;
                }
            }
            await this.rtc.client.subscribe(user, mediaType);
            if (mediaType === "audio") {
                const audioTrack = user.audioTrack;
                this.handleUserStatusChange({
                    user_id: user.uid,
                    audio: true,
                    type: 'other'
                })
                if (!this.options.preview_id) {
                    audioTrack.play();
                }
            } else {
                const videoTrack = user.videoTrack;
                this.handleUserStatusChange({
                    user_id: user.uid,
                    video: true,
                    type: 'other'
                })
                if (this.options.type == '1v1') {
                    videoTrack.play(this.doms.otherWindowDom);
                } else {
                    videoTrack.play(`window${user.uid}`);
                }
            }
        });
        this.rtc.client.on('user-left', (user, reason) => {             //用户离开
            if (String(user.uid).slice(-3) == '999') {                //预览模式离开
                return;
            }
            this.callBack.userLeft && this.callBack.userLeft(user, reason)
            this.handleUserStatusChange({
                user_id: user.uid,
                online: false,
                audio: false,
                video: false
            })
        })
        this.rtc.client.on('user-unpublished', async (user, mediaType) => {     //用户取消发布

            this.callBack.userUnpublished && this.callBack.userUnpublished(user, mediaType)
            if (mediaType == 'audio') {
                this.handleUserStatusChange({
                    user_id: user.uid,
                    audio: false,
                })
            } else {

                this.handleUserStatusChange({
                    user_id: user.uid,
                    video: false,
                })
            }

        })
    }
    handleUserStatusChange(params) {                                        //处理用户状态改变，并触发回调
        if (this.options.type == '1v1') {
            if (params.type == 'self') {
                delete params.type;
                this.userStatus1v1.selfStatus = Object.assign(this.userStatus1v1.selfStatus, params);
            } else {
                delete params.type;
                this.userStatus1v1.otherStatus = Object.assign(this.userStatus1v1.otherStatus, params);
            }
            this.callBack.userStatusChange && this.callBack.userStatusChange(this.userStatus1v1)
        }
        if (this.options.type == 'multi') {
            delete params.type;
            let _index = 0;
            let current = this.userStatusMulti.find((item, index) => {
                if (item.user_id == params.user_id) {
                    _index = index;
                    return true
                }
            });
            if (current) {
                current = Object.assign(current, params);
                this.userStatusMulti.splice(_index, 1, current)
            } else {
                this.userStatusMulti.push(params)
                current = params;
            }
            this.callBack.userStatusChange && this.callBack.userStatusChange(current)
        }
    }
    closeLocaleTrack(type) {                    //关闭本地音视频流 
        let localTrack = null;
        let status = {};
        if (type == 'audio') {
            localTrack = this.rtc.localAudioTrack
            localTrack.stop()
            status.audio = false;
        };
        if (type == 'video') {
            localTrack = this.rtc.localVideoTrack
            localTrack.stop();
            status.video = false;
        }
        this.handleUserStatusChange(Object.assign(status, {
            type: 'self',
            user_id: this.options.user_id
        }))
        this.rtc.client.unpublish(localTrack)
    }
    quitSDK() {
      
        this.handleUserStatusChange({
            online: false,
            video: false,
            audio: false,
            user_id: this.options.user_id,
            type: 'self'
        })

        if (this.rtc.localAudioTrack) {
            this.rtc.localAudioTrack.close()
        }
        if (this.rtc.localVideoTrack) {
            this.rtc.localVideoTrack.close()
        }
        // this.rtc.localAudioTrack.setEnabled(false)
        // this.rtc.localVideoTrack.setEnabled(false)
        if (this.rtcRemoteUser) {
            this.rtc.client.unsubscribe(this.rtcRemoteUser, '')
        }
        if (this.rtc.client) {
            this.rtc.client.leave()
        }
        this.rtc = {
            client: null,
            localAudioTrack: null,
            localVideoTrack: null,
        };
    }
}