<template>
  <div>
    <div class="miniAdv" v-if="miniShow" @click="showRed">
      <img
        src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/booth/%E7%BA%A2%E5%8C%85%E6%B5%AE%E7%AA%97.png"
        alt=""
        srcset=""
      />
      <p class="txt">{{ $t("getRed") }}</p>
    </div>
    <div class="hasAdv" v-if="advShow">
      <el-image
        @click="showRed"
        class="advImg"
        :src="'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/booth/%E7%BA%A2%E5%8C%85banner.png'"
      ></el-image>
      <p @click="showRed" class="advTxt">
        {{ $t("advNews") }}
      </p>
      <i @click="closeAdv" class="el-icon-error"></i>
    </div>
    <div class="redDialog" v-if="redDialog">
      <div class="redDialogContent">
        <img
          src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/booth/%E7%BA%A2%E5%8C%85%E5%BC%B9%E7%AA%97.png"
          alt=""
          srcset=""
        />
        <div class="redInfo">
          <el-image
            class="infoLogo"
            :src="
              companyInfo.company_logo ? companyInfo.company_logo : DEFAULT_LOGO
            "
            :fit="'cover'"
          ></el-image>
          <p
            class="infoName"
            :title="
              companyInfo.name_en | priorFormat(companyInfo.name_zh, LOCALE)
            "
          >
            {{ companyInfo.name_en | priorFormat(companyInfo.name_zh, LOCALE) }}
          </p>
          <p class="infoTitle">
            {{ redMsg.topic_en | priorFormat(redMsg.topic_zh, LOCALE) }}
          </p>
          <div class="alignCenter">
            <span class="openRed" round @click="getRedBack">{{
              $t("clickGet")
            }}</span>
          </div>
        </div>
        <i @click="closeRed" class="el-icon-error"></i>
      </div>
    </div>
    <div class="bussinessRed" v-if="bussinessRed">
      <div class="bussinessRedContent">
        <img
          src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/booth/bg.png"
          alt=""
          srcset=""
        />
        <i @click="closeBussinessRed" class="el-icon-error"></i>

        <div class="bussinessTxt">
          <p class="bussinesstitle">{{ $t("congratulations") }}</p>
          <p class="bussinessMoney">
            <span> {{ bussinessRedInfo.currency == 1 ? "￥" : "$" }}</span
            >{{ bussinessRedInfo.discount / 100 }}
          </p>
          <p class="bussinessFull" v-if="bussinessRedInfo.has_threshold">
            {{
              $t("overUser", {
                money:
                  (bussinessRedInfo.currency == 1 ? "￥" : "$") +
                  bussinessRedInfo.threshold / 100,
              })
            }}
          </p>
          <p class="bussinessFull" v-else>{{ $t("noOverUser") }}</p>
          <p class="bussinessTime">
            {{ $t("userTime") }}：{{
              bussinessRedInfo.using_start_time | secondFormat("LL")
            }}
            ~ {{ bussinessRedInfo.using_end_time | secondFormat("LL") }}
          </p>
          <p class="bussinessCompanymsg">
            {{ $t("setCompanyt") }}：{{
              companyInfo.name_en | priorFormat(companyInfo.name_zh, LOCALE)
            }}
          </p>
          <p class="bussinessCompanymsg">
            {{ $t("redNumber") }}：{{ bussinessRedInfo.receive_no }}
          </p>
          <p class="bussinessCompanymsg" style="margin-bottom: 15px">
            {{ $t("Instructions ") }}：{{
              bussinessRedInfo.instruction_en
                | priorFormat(bussinessRedInfo.instruction_zh, LOCALE)
            }}
          </p>
          <p class="passWord" v-if="passShow">
            {{ bussinessRedInfo.receive_pass }}
          </p>
          <div class="alignCenter" v-if="!passShow">
            <span class="lookPass" @click="loogkPass">
              {{ $t("lookPass") }}
            </span>
          </div>
          <div class="alignCenter" v-else>
            <span
              class="lookPass"
              @click="copyPass(bussinessRedInfo.receive_pass)"
              >{{ $t("copyPass") }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="momeyRed" v-if="moneyRed">
      <div class="moneyRedContent">
        <img
          src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/booth/%E7%8E%B0%E9%87%91%E7%BA%A2%E5%8C%85.png"
          alt=""
          srcset=""
        />
        <i @click="closeMoneyRed" class="el-icon-error"></i>
        <div class="moneyContentHasMoney" v-if="status == 1">
          <p class="moneyNumber">
            <span>￥</span>
            {{ bussinessRedInfo.amount / 100 }}
          </p>
          <p class="tips" @click="gotoMyRed">
            {{ $t("canEnter") }}<br />
            {{ $t("mianbaoxie") }}<br />
            {{ $t("toView") }}
          </p>
        </div>
        <div class="moneyContentNoMoney" v-if="status == 2">
          <p class="noMneyTips">
            {{ $t("toSlow") }}
          </p>
          <div class="noMoneyCompayInfo">
            <el-image
              class="infoLogo"
              :src="companyInfo.logo ? companyInfo.logo : DEFAULT_LOGO"
              :fit="'cover'"
            ></el-image>
            <p class="infoName">
              {{
                companyInfo.name_en | priorFormat(companyInfo.name_zh, LOCALE)
              }}
            </p>
            <p class="infoTitle">
              {{ redMsg.topic_en | priorFormat(redMsg.topic_zh, LOCALE) }}
            </p>
          </div>
        </div>
        <div class="moneyContentHasOpen" v-if="status == 3">
          <p class="hasOpenTips1">{{ $t("Hasreceived") }}</p>
          <p class="hasOpenTips2">
            {{ $t("canEnter2") }}<span @click="gotoMyRed"> {{ $t("mianbaoxie2") }} </span
            >{{ $t("toView") }}
          </p>
          <p class="hasOpenTips3" style="padding: 0 30px">
            {{ $t("firstAdd") }}
          </p>
          <div class="noMoneyCompayInfo">
            <el-image
              class="infoLogo"
              :src="companyInfo.logo ? companyInfo.logo : DEFAULT_LOGO"
              :fit="'cover'"
            ></el-image>
            <p class="infoName">
              {{
                companyInfo.name_en | priorFormat(companyInfo.name_zh, LOCALE)
              }}
            </p>
            <p class="infoTitle">
              {{ redMsg.topic_en | priorFormat(redMsg.topic_zh, LOCALE) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import boothMixin from "./mixin";
export default {
  mixins: [boothMixin],
  data() {
    return {
      advShow: false,
      miniShow: false,
      redDialog: false,
      bussinessRed: false,
      passShow: false,
      moneyRed: false,
      status: 1,
      redMsg: {},
      companyInfo: {},
      noRepeat: true,
    };
  },
  mounted() {
    this.companyId = this.$route.query.company_id;
    this.boothId = this.$route.query.booth_id;
    this.getBoothRedDetail();
    this.getCompanyInfo();
  },
  methods: {
    closeAdv() {
      this.advShow = false;
      this.miniShow = true;
    },
    closeRed() {
      this.redDialog = false;
    },
    closeBussinessRed() {
      this.bussinessRed = false;
      this.passShow = false;
    },
    loogkPass() {
      this.passShow = true;
    },
    gotoMyRed(){
      this.$router.push({
          path:'/console/conference/myRedEnvelope'
      })
    },
    copyPass(text) {
      if (!text) return;
      const input = document.createElement("textarea");
      document.body.appendChild(input);
      input.value = `${text}`;
      input.select();
      if (document.execCommand("copy")) {
        document.execCommand("copy");
      }
      document.body.removeChild(input);
      this.$message.success(this.$t("copySuccess"));
    },
    closeMoneyRed() {
      this.moneyRed = false;
    },

    async getCompanyInfo() {
      let id = this.$route.query.company_id || "";
      let params = {
        company_id: id,
        visit_company_id: this.USER_INFO.company_id,
        visit_user_id: this.USER_INFO.id,
      };
      try {
        let data = await this.$store.dispatch(
          "baseStore/getBoothCompanyInfo",
          params
        );
        this.initComplete();
        if (data.success) {
          this.companyInfo = data.data;
        }
      } catch (error) {}
    },
    async getBoothRedDetail() {
      let data = await this.$store.dispatch("baseStore/getBoothRedDetail", {
        company_id: this.companyId,
        meeting_id: this.MEETING_ID,
      });
      if (data.envelope_type != 3) {
        let nowData = this.$moment().unix();
        if (nowData > data.start_time && nowData < data.end_time) {
          this.advShow = true;
          this.redMsg = data;
        }
      }
    },
    async getRedBack() {
      let data = await this.$store.dispatch("baseStore/getRed", {
        user_id: this.USER_ID,
        envelope_type: this.redMsg.envelope_type,
        envelope_id: this.redMsg.envelope_id,
        booth_no: this.boothId,
        company_id: this.USER_INFO.company_id,
        meeting_id: this.MEETING_ID,
        red_envelop_company_id :this.companyId
      });
      if (data.success) {
        if (data.data.envelope_type == 1) {
          this.redDialog = false;
          this.bussinessRed = true;
          this.bussinessRedInfo = data.data;
        } else {
          this.redDialog = false;
          this.moneyRed = true;
          this.status = 1;
          this.bussinessRedInfo = data.data;
        }
      } else {
        this.redDialog = false;
        this.moneyRed = true;
        this.status = 2;
      }
    },
    async showRed() {
      if(this.$route.query.isPreview){
        return
      }
      if (!this.USER_ID) {
        this.$router.push("/sign");
        return;
      }
      if (!this.ENROLL_INFO.is_user_enroll) {
        this.$router.push("/register");
        return;
      }
      if (this.USER_ID && this.ENROLL_INFO.audit_status == 0) {
        this.$message({
          message: this.$t("waitAuith"),
          type: "warning",
        });
        return;
      }
      if (this.USER_ID && this.ENROLL_INFO.audit_status == 2) {
        this.$message({
          message: this.$t("exp_not_tongguo"),
          type: "warning",
        });
        return;
      }
      if (!this.noRepeat) {
        return;
      }
      this.noRepeat = false;
      let res = await this.$store.dispatch("baseStore/getUserRedOnce", {
        envelope_id: this.redMsg.envelope_id,
        envelope_type: this.redMsg.envelope_type, // 类型：1业务，2现金
        user_id: this.USER_ID,
        meeting_id: this.MEETING_ID,
        company_id: this.companyId,
      });
      this.noRepeat = true;
      if (!res.receive_num) {
        this.moneyRed = true;
        this.status = 3;
        return;
      }

      let data = await this.$store.dispatch("baseStore/getLeftRedNumber", {
        envelope_id: this.redMsg.envelope_id,
        envelope_type: this.redMsg.envelope_type, // 类型：1业务，2现金
      });

      if (data.left_num) {
        this.redDialog = true;
      } else {
        this.moneyRed = true;
        this.status = 2;
      }
    },
  },
};
</script>

<style scoped lang="less">
.miniAdv {
  position: fixed;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 176px;
  height: 186px;
  z-index: 999;
  img {
    width: 176px;
    height: 186px;
  }
  .txt {
    position: absolute;
    left: 0;
    bottom: 25px;
    width: 100%;
    font-size: 16px;
    font-weight: 900;
    text-align: center;
    color: rgb(253, 241, 224);
  }
}
.momeyRed {
  z-index: 9999;
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  .moneyRedContent {
    width: 305px;
    height: 408px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    img {
      width: 305px;
      height: 408px;
    }
    .el-icon-error {
      font-size: 40px;
      position: absolute;
      bottom: -50px;
      left: 50%;
      transform: translateX(-50%);
    }
    .moneyContentNoMoney,
    .moneyContentHasOpen,
    .moneyContentHasMoney {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
    .moneyContentHasOpen {
      .hasOpenTips1 {
        padding-top: 88px;
        color: #dd9740;
        font-size: 21px;
        text-align: center;
        font-weight: 600;
      }
      .hasOpenTips2 {
        font-size: 12px;
        color: #333;
        text-align: center;
        padding-top: 5px;
        span {
          color: #027fff;
        }
      }
      .hasOpenTips3 {
        font-size: 12px;
        text-align: center;
        color: #999;
        margin-top: 17px;
      }
      .noMoneyCompayInfo {
        width: 100%;
        padding: 0 30px;
        text-align: center;
        margin-top: 68px;
        .infoLogo {
          width: 48px;
          height: 48px;
        }
        .infoName {
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 16px;
          color: #fff;
          margin: 15px 0;
        }
        .infoTitle {
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 16px;
          color: #fff;
        }
      }
    }
    .moneyContentNoMoney {
      .noMneyTips {
        font-size: 21px;
        font-weight: 600;
        text-align: center;
        padding-top: 118px;
        color: #dd9740;
      }
      .noMoneyCompayInfo {
        width: 100%;
        padding: 0 30px;
        text-align: center;
        margin-top: 88px;
        .infoLogo {
          width: 48px;
          height: 48px;
        }
        .infoName {
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 16px;
          color: #fff;
          margin: 15px 0;
        }
        .infoTitle {
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 16px;
          color: #fff;
        }
      }
    }
    .moneyContentHasMoney {
      .moneyNumber {
        width: 100%;
        font-size: 48px;
        font-weight: 600;
        color: #dd9740;
        padding-top: 97px;
        text-align: center;
        span {
          font-size: 24px;
        }
      }
      .tips {
        color: #fff;
        font-size: 18px;
        margin-top: 76px;
        text-align: center;
        line-height: 35px;
      }
    }
  }
}
.alignCenter {
  text-align: center;
}
.hasAdv {
  z-index: 9999;
  width: 1300px;
  position: fixed;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  .advImg {
    width: 100%;
    height: 200px;
    display: block;
  }
  .advTxt {
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 200px;
    line-height: 200px;
    padding-top: 20px;
    font-size: 50px;
    font-weight: 900;
    font-style: oblique;
    color: rgb(188, 57, 29);
    text-align: center;
  }
  .el-icon-error {
    color: #999;
    font-size: 42px;
    position: absolute;
    right: 0;
    top: 0;
  }
}
.redDialog {
  z-index: 9999;
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  .redDialogContent {
    width: 448px;
    height: 555px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    img {
      width: 448px;
      height: 555px;
    }
    .el-icon-error {
      font-size: 40px;
      position: absolute;
      left: 50%;
      bottom: -60px;
      transform: translateX(-50%);
    }

    .redInfo {
      width: 330px;
      position: absolute;
      top: 270px;
      left: 50%;

      transform: translateX(-50%);
      .infoLogo {
        width: 72px;
        height: 72px;
        margin: 0 auto;
        display: block;
      }
      .infoName,
      .infoTitle {
        width: 100%;
        text-align: center;
        color: #fff;
        font-size: 21px;
        font-weight: 600;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin: 20px 0;
      }
      .infoTitle {
        font-size: 36px;
        color: #feffb3;
      }
      .openRed {
        margin: 0 auto;
        cursor: pointer;
        border-radius: 30px;
        text-align: center;
        padding: 10px 50px;
        color: rgb(213, 43, 87);
        display: inline-block;
        background: linear-gradient(180deg, #ffe071 0%, #ffb83c 100%);
        box-shadow: 0px 4px 22px 0px rgba(160, 110, 17, 0.61),
          0px 2px 6px 0px rgba(255, 255, 255, 0.78);
        border: none;
        font-weight: 900;
        font-size: 21px;
      }
    }
  }
}
.bussinessRed {
  z-index: 9999;
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);

  .bussinessRedContent {
    width: 347px;
    height: 532px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .el-icon-error {
      font-size: 40px;
      position: absolute;
      bottom: -50px;
      left: 50%;
      transform: translateX(-50%);
    }
    img {
      width: 347px;
      height: 532px;
    }
    .bussinessTxt {
      width: 347px;
      height: 532px;
      position: absolute;
      left: 0;
      top: 0;
      .bussinesstitle {
        color: #fff;
        text-align: center;
        line-height: 70px;
        font-size: 20px;
        font-style: oblique;
      }
      .bussinessMoney {
        margin-top: 30px;
        text-align: center;
        color: rgb(223, 98, 117);
        font-size: 70px;
        font-weight: 900;
        span {
          font-size: 31px;
        }
      }
      .bussinessFull {
        color: #666;
        text-align: center;
        font-size: 16px;
      }
      .bussinessTime {
        color: #999;
        text-align: center;
        font-size: 14px;
        margin-top: 20px;
        margin-bottom: 70px;
      }
      .bussinessCompanymsg {
        color: #fff;
        margin-top: 20px;
        padding: 0 20px;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .passWord {
        width: 300px;
        background: #fff;
        line-height: 30px;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 0;
      }
      .lookPass {
        margin: 0 auto;
        padding: 6px 30px;
        border-radius: 30px;
        text-align: center;
        display: inline-block;
        margin: 15px 0;
        color: rgb(213, 43, 87);
        background: linear-gradient(180deg, #ffe071 0%, #ffb83c 100%);
        box-shadow: 0px 4px 22px 0px rgba(160, 110, 17, 0.61),
          0px 2px 6px 0px rgba(255, 255, 255, 0.78);
        border: none;
        font-weight: 900;
        font-size: 18px;
        cursor: pointer;
      }
    }
  }
}
</style>