<template>
  <div class="plLivePlayComponents">
    <div id="ppt_content" class="ppt_content"></div>
    <div class="live_content" id="live_content"></div>
    <div class="joinRoom" v-if="showJoinBtn&&isLiving">
      <el-button size="small" round type="primary" @click="joinRoom">{{$t('enterLiveRoom')}}</el-button>
    </div>
  </div>
</template>
<script>

import { priorFormat } from "~/basePlugins/filters";
import { randomString } from "~/baseUtils";
export default {
  props: {
    channelId: {
      type: String,
      default: "2195663",
    },
    showJoinBtn: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      playerJs: "https://player.polyv.net/livesdk/polyv-live.min.js",
      appId: "fkyterpju6",
      liveSdk: null,
      liveAlady: false,
      isLiving:false,
    };
  },
  mounted() {
    this.loadPlayerScript(this.initLiveContent);
  },
  methods: {
    joinRoom(){
      window.open(`https://live.polyv.cn/watch/${this.channelId}`)
    },
    loadPlayerScript(callback) {
      if (!window.polyvLivePlayer) {
        const myScript = document.createElement("script");
        myScript.setAttribute("src", this.playerJs);
        myScript.onload = callback;
        document.body.appendChild(myScript);
      } else {
        callback();
      }
    },

    async initLiveContent() {
      let user = {};
      if (this.IS_LOGIN) {
        user = {
          userId: this.USER_ID + "",
          userName: priorFormat(
            this.USER_INFO.name_en,
            this.USER_INFO.name_zh,
            this.LOCALE
          ),
          pic: this.USER_INFO.avatar || this.DEFAULT_AVATAR,
        };
      } else {
        user = {
          userId: randomString(8),
          userName: "AiLa会议用户",
          pic: this.DEFAULT_AVATAR,
        };
      }
      let channelId = this.channelId;
      let timestamp = this.$moment().unix() * 1000;
      let result = await this.$store.dispatch("baseStore/getpolyvSign", {
        channel_id: channelId,
        timestamp: timestamp,
      });
      if (result.success) {
        this.liveSdk = new PolyvLiveSdk({
          channelId: channelId,
          sign: result.data,
          timestamp: timestamp,
          appId: this.appId,
          user,
        });
        this.liveSdk.on(
          PolyvLiveSdk.EVENTS.CHANNEL_DATA_INIT,
          (event, data) => {
            if (data.watchStatus == "live") {
              this.isLiving = true;
              this.liveAlady = true;
              this.liveSdk.setupPlayer({
                pptEl: "#ppt_content",
                el: "#live_content",
                type: "auto",
                autoplay: true,
              });
              this.$emit("liveStart");
            } else {
               this.isLiving = false;
            }
          }
        );
        this.liveSdk.on(PolyvLiveSdk.EVENTS.STREAM_UPDATE, (event, data) => {
          if (data == "live") {
            this.isLiving = true;
            if (this.liveAlady) {
              this.liveSdk.reloadPlayer({});
            } else {
              this.liveAlady = true;
              this.liveSdk.setupPlayer({
                pptEl: "#ppt_content",
                el: "#live_content",
                type: "auto",
                autoplay: true,
              });
            }
            this.$emit("liveStart");
          } else {
            this.isLiving = false;
            this.$emit("liveEnd");
          }
        });
      }
      console.log(result);
    },
    closeLive() {
      this.liveSdk.destroy();
      this.liveSdk = null;
      this.liveAlady = false;
    },
  },
  watch: {
    channelId(nv) {
      this.liveSdk.destroy();
      this.liveSdk = null;
      this.liveAlady = false;
      this.initLiveContent();
    },
  },
  destroyed() {
    this.liveSdk.destroy();
    this.liveSdk = null;
    this.liveAlady = false;
  },
};
</script>
<style scoped lang="less">
.plLivePlayComponents {
  width: 100%;
  height: 100%;
  background: #333;
  position: relative;
  .live_content {
    width: 100%;
    height: 100%;
  }
  .ppt_content {
    display: none;
  }
  .joinRoom {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 3000;
    text-align: center;
    .el-button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>